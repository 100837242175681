import * as React from 'react';
import { HotkeyProvider, HotkeyHandler } from '@mentimeter/hotkeys';
import type { DefaultValidKeyCodesT } from '@mentimeter/hotkeys';
import type { PromptGenericT } from '../Prompt/PromptGeneric';
import { PromptGeneric } from '../Prompt/PromptGeneric';
import ModalGeneric from './ModalGeneric';

const defaultProps = {
  width: '95vw',
  maxWidth: '800px',
  my: 3,
};

const HOTKEYS: DefaultValidKeyCodesT[] = ['escape'];

export interface ModalT extends Omit<PromptGenericT, 'dismissLabel'> {
  children: React.ReactNode;
  show: boolean;
  center?: boolean;
}

/**
 * @deprecated Use the new accessible modal components instead. [Link to Storybook](https://storybook.mentimeter.org/?path=/docs/global-system-ragnar-ui-ui-modal--docs)
 */

export const Modal = ({
  children,
  show,
  onDismiss,
  actions,
  theme,
  center,
  ...props
}: ModalT) => (
  <ModalGeneric theme={theme} center={center} show={show} onDismiss={onDismiss}>
    <HotkeyProvider keyStrokes={HOTKEYS}>
      {show && <HotkeyHandler keyCode="escape" onKeyDown={onDismiss} />}
    </HotkeyProvider>
    <PromptGeneric
      layer="far"
      dismissLabel="Close"
      onDismiss={onDismiss}
      actions={actions}
      actionPattern="z"
      {...defaultProps}
      {...props}
    >
      {children}
    </PromptGeneric>
  </ModalGeneric>
);
