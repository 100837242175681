import * as React from 'react';
import { Box, type BoxT } from '../Box';
import { Button, type ButtonT } from '../Button';

export interface GroupActionT {
  actions?: Array<ButtonT & { key: string }> | undefined;
}

const ACTION_PATTERN = Object.freeze({
  Z: 'z',
  F: 'f',
  C: 'c',
});

export type ActionPatternT =
  (typeof ACTION_PATTERN)[keyof typeof ACTION_PATTERN];

const ACTION_PATTERN_MAP: {
  [key in ActionPatternT]: {
    flexDirection: BoxT['flexDirection'];
    justifyContent: BoxT['justifyContent'];
    mr: BoxT['mr'];
    ml: BoxT['ml'];
  };
} = {
  z: {
    flexDirection: 'row-reverse',
    justifyContent: 'end',
    mr: 'space2',
    ml: 'space0',
  },
  f: {
    flexDirection: 'row',
    justifyContent: 'start',
    mr: 'space0',
    ml: 'space2',
  },
  c: {
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    mr: 'space2',
    ml: 'space0',
  },
};

export interface GroupT extends BoxT, GroupActionT {
  size?: ButtonT['size'];
  autoFocus?: boolean | undefined;
  actionPattern?: ActionPatternT;
}

export const Group = ({
  autoFocus,
  actions,
  size = 'compact',
  actionPattern = ACTION_PATTERN.F,
  ...props
}: GroupT) => {
  return (
    actions &&
    actions.length > 0 && (
      <Box
        width="100%"
        {...props}
        flexDirection={ACTION_PATTERN_MAP[actionPattern].flexDirection}
        justifyContent={ACTION_PATTERN_MAP[actionPattern].justifyContent}
      >
        {actions.map(({ key, ...props }, i) =>
          i === 0 ? (
            <Button
              key={key}
              variant="primary"
              {...props}
              autoFocus={autoFocus}
              size={size}
            />
          ) : (
            <Button
              key={key}
              {...props}
              variant="secondary"
              mr={ACTION_PATTERN_MAP[actionPattern].mr}
              ml={ACTION_PATTERN_MAP[actionPattern].ml}
              size={size}
            />
          ),
        )}
      </Box>
    )
  );
};
