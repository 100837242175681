import * as React from 'react';
import * as Yup from 'yup';
import { TextInput, Form, Button } from '@mentimeter/ragnar-form';
import type { FormikHelpers } from 'formik';
import { useTranslation } from '@mentimeter/i18n';
import {
  createEmailValidation,
  createPasswordValidation,
} from '../helpers/copy';
import { PasswordInput } from './PasswordInput';

export const useLoginValidationSchema = () => {
  const { t } = useTranslation('authPackage');

  return Yup.object().shape({
    ...createEmailValidation(t),
    /// FYI: as for now, we need to support 6 characters long password due to legacy reasons for some users
    ...createPasswordValidation(t, 6),
  });
};

interface SubmitParamsT {
  email: string;
  password: string;
}

export interface PropsT {
  onSubmit: (arg0: SubmitParamsT, arg1: FormikHelpers<SubmitParamsT>) => void;
  isLoading: boolean;
  disabled: boolean;
  initialEmail?: string | undefined;
  initialPassword?: string | undefined;
  emailChangeDisabled?: boolean;
}

const LogInForm = ({
  onSubmit,
  isLoading,
  disabled,
  initialEmail,
  initialPassword,
  emailChangeDisabled,
}: PropsT) => {
  const { t } = useTranslation('authPackage');
  const validationSchema = useLoginValidationSchema();
  return (
    <Form
      initialValues={{
        email: initialEmail || '',
        password: initialPassword || '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      width={1}
      alignItems="stretch"
    >
      <TextInput
        name="email"
        type="email"
        label={t('form_email_label')}
        disabled={emailChangeDisabled}
      />
      <PasswordInput name="password" label={t('login_form_password_label')} />
      <Button
        variant="primary"
        type="submit"
        state={isLoading ? 'loading' : undefined}
        my={2}
        disabled={disabled}
        data-testid="login-btn"
      >
        {t('login')}
      </Button>
    </Form>
  );
};

export default LogInForm;
