import * as React from 'react';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import { Clickable, type ClickableT } from '../Clickable';

export interface DismissT extends Pick<ClickableT, 'p'> {
  dismissLabel?: string | undefined;
  onDismiss?: undefined | (() => void);
}

export const Dismiss = ({
  onDismiss,
  dismissLabel = '',
  p = 'space4',
}: DismissT) => {
  if (!(onDismiss && dismissLabel)) {
    return null;
  }

  return (
    <Clickable
      p={p}
      aria-label={dismissLabel}
      onClick={onDismiss}
      color="currentColor"
      zIndex={1}
      type="button"
    >
      {dismissLabel === '' ? (
        <CrossIcon size={3} />
      ) : (
        <CrossIcon aria-hidden={true} size={3} />
      )}
    </Clickable>
  );
};
