import type { Rule } from '@mentimeter/ragnar-styled';
import { styled } from '@mentimeter/ragnar-styled';

/**
 * Portal helper for aligning portal elements.
 * This is the only component that should have `pointerEvents`
 * as it's responsible for making the UI snappy even though animations
 * happen.
 */
interface Props {
  center?: boolean;
  zIndex?: number;
}

const rule: Rule<Props> = (props) => ({
  position: 'fixed',
  zIndex: props.zIndex !== undefined ? props.zIndex.toString() : 1,
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  overflow: 'auto',
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'center',
  ...(props.center === true
    ? {
        alignItems: 'center',
      }
    : {}),
});

export const PortalHelper = styled('div', {
  displayName: 'PortalHelper',
})(rule);
